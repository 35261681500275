.menu-container {
    padding: 16px 0px 0px 16px;
    flex-direction: column;
    background-color: map-get($map: $theme-colors, $key: "BLACK");
    width: 100%;
    .day-mode & {
        background-color: map-get($map: $theme-colors, $key: "DAY_MODE_GREY");
    }

    .nav-container {
        .nav-item {
            height: 24px;
            margin: 12px 0px;
            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;

            &:hover {
                cursor: pointer;
            }

            .nav-icon-container {
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;

                .nav-icon {
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                }
            }

            .nav-text {
                color: map-get($map: $theme-colors, $key: "GREY");
            }

            .nav-text.active {
                color: map-get($map: $theme-colors, $key: "WHITE");
                .day-mode & {
                    color: map-get($map: $theme-colors, $key: "BLACK");
                }
            }
        }

        .nav-item.active {
            border-right: 3px solid map-get($map: $theme-colors, $key: "ICON_ORANGE")
        }
    }

    .categories-container {
        margin-top: 24px;

        .categories-headline {
            margin: 16px 0px;
            font-size: 0.8rem;
            color: map-get($map: $theme-colors, $key: "ICON_ORANGE")
        }

        .category-item {
            color: map-get($map: $theme-colors, $key: "GREY");
            margin: 16px 0px;

            &:hover {
                cursor: pointer;
            }
            &.active {
                border-right: 3px solid map-get($map: $theme-colors, $key: "ICON_ORANGE");
                color: map-get($map: $theme-colors, $key: "WHITE");
                .day-mode & {
                    color: map-get($map: $theme-colors, $key: "BLACK");
                }
            }
            &.disabled {
                opacity: .6;
            }
        }
    }
}

//Layout for small Smartphones
@media only screen and (min-width: $media-break-6) {}

//Layout for large Smartphones
@media only screen and (min-width: $media-break-4) {

    .menu-container {
        display: flex;
        position: static;
        width: 200px;
    }
}

//Layout for Tablet
@media only screen and (min-width: $media-break-3) {
}

//Layout for Desktop
@media only screen and (min-width: $media-break-2) {
    .menu-container {
        padding: 16px 0px 0px 24px;
        width: 220px;
    }
}

//Layout for Desktop
@media only screen and (min-width: $media-break-1) {
    .menu-container {
        padding: 16px 0px 0px 40px;
        width: 240px;
    }
}