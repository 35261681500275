.TermsConditions {
    display: flex;
    justify-content: center;
    padding: 10% 5%;
    background: white;
    color: black;
    font-size: 20px;
    img {
        max-height: 64px;
        @media all and (max-width: 550px) {
            width: 100%;
        }
    }
    * {
        margin-bottom: 30px;
    }
    .content-container {
        max-width: 1200px;
        h5 {
            font-size: 20px;
            margin-bottom: 5px;
        }
        .tableContents {
            span {
                display: block;
            }
            span:last-child() {
                margin-bottom: 60px;
            }
        }
    }
}
