.listview {
    display: flex;
    flex-direction: column;
    flex: 1;
    .listview-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-width: 0;
        flex-shrink: 0;

        height: 28px;
        font-size: 0.75rem;
        color: map-get($map: $theme-colors, $key: "GREY");
        background-color: map-get($map: $theme-colors, $key: "LIGHT_BLACK");
        .day-mode & {
            background-color: map-get($map: $theme-colors, $key: "TEXT_WHITE");
        }
    }
    .listview-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-width: 0;
        flex-shrink: 0;

        .chevron {
            height: .7rem;
            transform: rotate(90deg);
            margin-left: 0.4rem;
            margin-top: .2rem;
            filter: invert(56%) sepia(73%) saturate(2737%) hue-rotate(344deg) brightness(101%) contrast(94%);
            &:nth-child(1),
            &:nth-child(2) {
                margin-bottom: .3rem;
            }
        }

        .play-column {
            display: flex;
            flex: 0 0 28px;
            justify-content: center;

            .play-icon {
                width: 13px;
                height: 13px;
                object-fit: contain;
                display: none;
                &.playing {
                    display: inline;
                }
            }
        }

        .link  {
            cursor: pointer;
            color: #fff;
            &:hover {
                text-decoration: underline;
            }
        }

        .title-artist-column {
            padding-right: 8px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            flex: 1;
            cursor: pointer;
        }

        .exclusive-svg {
            filter: invert(68%) sepia(45%) saturate(5761%) hue-rotate(343deg) brightness(99%) contrast(75%);
            width: 4rem;
            margin-right:35px;
            display: none;
        }

        .bpm-column {
            display: none;
            cursor: pointer;
        }

        .genre-column {
            flex: 0 0 60px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-right: 8px;
        }

        .date-column {
            display: none;
            cursor: pointer;
        }

        .key-column {
            position: relative;
            display: none;
            cursor: pointer;
            .key:hover {
                & ~ .key-hover {
                    visibility: visible;
                }
            }
        }

        .key-hover {
            position: absolute;
            white-space: nowrap;
            padding: 5px 20px;
            left: 50px;
            top: -7px;
            overflow: show;
            z-index: 99;
            background: map-get($map: $theme-colors, $key: "DARK_GREY_2");
            border-radius: 5px;
            visibility: hidden;
            .day-mode & {
                color: map-get($map: $theme-colors, $key: "GREY");
                background-color: map-get($map: $theme-colors, $key: "TEXT_WHITE");
            }
        }

        .type-column {
            display: none;
        }

        .column {
            height: 72px;
        }
    }
    .listview-item {
        height: 72px;
        align-items: center;
        font-size: 0.875rem;
        color: map-get($map: $theme-colors, $key: "WHITE");
        border-top: 1px solid map-get($map: $theme-colors, $key: "DARK_GREY");
        .day-mode & {
            border-top: 1px solid #E9E9E9;
            color: map-get($map: $theme-colors, $key: "BLACK");
            .link {
                color: map-get($map: $theme-colors, $key: "BLACK");
            }
        }
        &:hover {
            background-color: #000;
            border-top-color: #000;
            .day-mode & {
                border-top-color: #E9E9E9;
                background-color: map-get($map: $theme-colors, $key: "DAY_MODE_GREY");
            }
            &+.listview-item {
                border-top-color: #000;
                .day-mode & {
                    border-top-color: #E9E9E9;
                }
            }
            .play-icon {
                display: inline;
                &.playing {
                    display: none;
                }
            }
        }
        .clickable {
            cursor: pointer;
        }
    }
    .listview-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: hidden;
        padding-bottom: 4rem;

        .load-more-container {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
    }
    .pagination-container {
        display: block;
        text-align: center;
        ul {
            float: right;
            padding-top: 20px;
            li {
                display:block;
                position: relative;
                float: left;
                list-style: none;
                
                margin-left: 4px;
                margin-right: 4px;
                padding:0;
                               
                border-radius: 15px;
                min-width: 30px;
                height: 30px;
                &.more {
                    .day-mode & {
                        color: #000;
                    }
                }
                &.selected {
                    a {
                        span {
                            color: #fff;
                        }
                    }
                    background-color: map-get($map: $theme-colors, $key: "ORANGE");
                }
                &:hover:not(.selected) {
                    .no-touch & {
                        &:not(.more):not(.input) {
                            a {
                                span {
                                    color: #fff;
                                }
                            }
                            background-color: map-get($map: $theme-colors, $key: "ORANGE");
                        }
                    } 
                }
                a {
                    text-decoration: none;
                    padding-left: 10px;
                    padding-right:10px;
                    span {
                        line-height: 30px;
                    }
                }
            }
        }
    }
} 


//Layout for Smartphone
@media only screen and (min-width: $media-break-5) {
    .listview {
        .listview-row {
            .bpm-column {
                display: flex;
                flex: 0 0 65px;
            }
            .key-column {
                display: flex;
                flex: 0 0 32px;
                justify-content: center;
                margin-right: 6px;
            }
            // .title-artist-column {
            //     &.artist {
            //         margin-left: -108px;
            //     }
            // }
        }
    }
}


//Layout for Tablet
@media only screen and (min-width: $media-break-3) {
    .listview {
        .listview-row {
            .genre-column {
                flex: 0 0 80px;
            }

            .type-column {
                display: flex;
                flex-direction: row;
                flex: 0 0 88px;

                .type-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 28px;
                    height: 28px;
                    &:not(.downloaded) {
                        .day-mode & {
                            filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(1deg) brightness(0%) contrast(60%);
                        }
                    }
                    .icon {
                        width: 20px;
                        //height: 20px;
                        object-fit: contain;
                    }
                }
            }
        }
        .listview-header {
            padding-right: 1rem;
            color: map-get($map: $theme-colors, $key: "TIMELINE_LIGHT");
        }
        .listview-container {
            padding-right: 1rem;
        }
    }
}

//Layout for Desktop
@media only screen and (min-width: $media-break-2) {
    .listview {
        .listview-header {
            padding-right: 4rem;
        }
        .listview-container {
            overflow-y: scroll;
            padding-right: 4rem;
        }
        .listview-row {
            .genre-column {
                flex: 0 0 120px;
            }
            .date-column {
                display: flex;
                flex: 0 0 96px;
                cursor: pointer;
            }
        }
    }
    
}

//Layout for large Desktop
@media only screen and (min-width: $media-break-1) {
    .listview {
        .listview-header {
            position: -webkit-sticky; /* Safari */
            position: sticky;
            top: 0;
        }
        .listview-container {
            overflow-y: scroll;
        }
        .listview-row {
            .exclusive-svg {
                display: block;
            }
            .play-column {
                flex: 0 0 40px;
            }

            .bpm-column {
                flex: 0 0 70px;
            }

            .key-column {
                flex: 0 0 56px;
            }

            .type-column {
                flex: 0 0 96px;

                .liked {
                    margin-left: 8px;
                }
            }
            .title-artist-column {
                &.artist {
                    margin-left: -108px;
                }
            }
        }
    }
}

.rotate-down {
    transform: rotate(-90deg) !important;
}

.hidden {
    visibility: hidden;
}