.input-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 12px;

    span {
        font-size: 0.875rem;
        color: map-get($map: $theme-colors, $key: "WHITE")
    }

    .input {
        margin: 12px 0px;
        height: 47px;
        border-radius: 6px;
        background-color: map-get($map: $theme-colors, $key: "TEXT_WHITE");
        font-size: 0.875rem;
        color: map-get($map: $theme-colors, $key: "LIGHT_BLACK");
        border: none;
        padding: 0px 16px;
    }
}

//Layout for Smartphone


//Layout for Tablet
@media only screen and (min-width: $media-break-3) {}

//Layout for Desktop
@media only screen and (min-width: $media-break-2) {


}