.Homepage {
    header {
        padding: 45px 67px 0 67px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .content-left {
            display: flex;
            align-items: center;
            .icon {
                width: 18rem;
                margin-right: 1rem;
            }
        }
        .content-right {
            display: flex;
            align-items: center;
            .login-text {
                cursor: pointer;
                margin-right: 1.5rem;
            }
            .try-button {
                font-family: 'Montserrat-SemiBold';
                background: map-get($theme-colors, "ORANGE");
                padding: 1rem 1.5rem;
                border: none;
                border-radius: 25rem;
                color: white;
            }
        }
    }
    .top-content {
        padding: 125px;
        display: flex;
        justify-content: center;
        align-items:center;
        flex-direction:column;
        .header-text {
            font-family: 'MonumentExtended-Regular';
            font-size: 70px; 
            max-width: 1120px;
            text-align: center;
        }
        .subtext {
            margin-top: 10px;
            font-size: 24px;
            text-align: center;
        }
        .cost {
            font-family: 'MonumentExtended-Regular';
            font-size: 20px;
            margin: 25px 0;
            white-space: nowrap;
            span {
                font-size: 50px;
            }
        }
        .browse-button {
            font-family: 'Montserrat-SemiBold';
            background: map-get($theme-colors, "ORANGE");
            padding: 20px 40px;
            border: none;
            border-radius: 30px;
            color: white;
        }
    }
    .bottom-content {
        padding: 125px;
        background: map-get($theme-colors, "ORANGE");
        display: flex;
        justify-content: center;
        align-items:center;
        flex-direction:column;
        .subtext {
            text-align: center;
        }
        .header-text {
            font-family: 'MonumentExtended-Regular';
            max-width: 700px;
            text-align: center;
        }
        .Album-row {
            margin: 67px 0;
            display: flex;
            
            .item {
                display: flex;
                flex-direction: column;
                margin-right: 24px;
                &:last-child() {
                    margin-right: 0;
                }
                .artwork {
                    width: 240px;
                }
                .title {
                    font-family: 'Montserrat-SemiBold';
                    margin: 20px 0 0 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 235px;
                    font-size: 18px;

                }
                .artist {
                    font-size: 16px;
                }
            }
        }
        .browse-button {
            font-family: 'Montserrat-SemiBold';
            background: map-get($theme-colors, "WHITE");
            padding: 20px 40px;
            border: none;
            border-radius: 30px;
            color: map-get($theme-colors, "BLACK");
        }
    }
    footer {
        padding: 45px 313px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: map-get($theme-colors, "BLACK");

        .content-left {
            display: flex;
            align-items: center;
            .icon {
                width: 18rem;
                margin-right: 1rem;
            }
        }
        .content-right {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: rgba(map-get($theme-colors, "WHITE"), 0.7);
        }
    }
}

@media screen and (max-width: $media-break-1) {
    .Homepage {
        .top-content {
            .header-text {
                font-size: 50px;
            }
            .subtext {
                font-size: 20px;
            }
        }
        .bottom-content {
            padding: 60px 0 60px 24px;
            .Album-row {
                display: flex;
                justify-content: center;
                max-width: 792px;
                flex-wrap: wrap;
                margin-top: 43px;
                .item {
                    margin-top:24px;
                }
            }
        }
        footer {
            padding: 45px 100px;
        }
    }
}

@media screen and (max-width: $media-break-5) {
    .Homepage {
        header {
            padding: 20px;
            .content-left {
                .icon {
                    width: 140px;
                }
                span {
                    font-size: 12px;
                }
            }
            .content-right {
                .login-text {
                    margin-right: 20px;
                    font-size: 12px;
                }
                .try-button {
                    padding: .5rem 1rem;
                    font-size: 14px;
                }
            }
        }
        .top-content {
            padding: 40px;
            padding-bottom: 60px;
            .header-text {
                font-size: 32px;
            }
            .cost {
                margin: 0;
                margin-bottom: 20px;
                span {
                    font-size: 28px;
                }
            }
            .browse-button {
                padding: 15px 30px;
            }
        }
        .bottom-content {
            padding: 60px 40px;

            .Album-row {
                margin-top: 20px;
                margin-bottom: 44px;
                .item {
                    margin-left: 0;
                    margin-right: 0;
                    .title {
                        margin-top: 10px;
                    }
                };
            }
            .browse-button {
                padding: 15px 30px;
            }
        }
        footer {
            padding: 20px;
            .content-left {
                .icon {
                    width: 120px;
                }
                span {
                    font-size: 12px;
                }
            }
            .content-right {
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width: $media-break-6) {
    .Homepage {
        .header {
            padding-bottom: 0;
            .content-left .icon {
                margin-right: 10px
            }
            .content-right .login-text {
                margin-right: 10px;
            }
            .content-right .try-button {
                font-size: 12px;
            }
        }
        .top-content {
            padding: 80px 15px;
            .header-text {
                font-size: 28px;
            }
            .subtext {
                margin: 5px 0;
            }
            .cost {
                font-size: 18px;
                margin-bottom: 35px;
            }
        }
        .bottom-content {
            padding: 80px 15px;
            .header-text {
                font-size: 28px;
                max-width: 84%;
            }
        }
    }
}

@media screen and (max-width: 340px) {
    .Homepage {
        .bottom-content {
            .header-text {
                max-width: unset;
            }
        }
    }
}