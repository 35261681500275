.login {
    display: inline-block;
    margin: auto;

    .login-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px;

        .logo {
            cursor: pointer;
            width: 280px;
            object-fit: contain;
        }

        .login-box {
            width: 280px;
            margin: 40px 0px;
            padding: 16px;

            .headline {
                margin-bottom: 28px;
            }

            .keep-me {
                display: flex;
                flex-direction: row;
                padding: 12px 0px;
                align-items: center;

                .label {
                    font-size: 0.875rem;
                    color: map-get($map: $theme-colors, $key: "TEXT_GREY");
                }

                .label.left-space {
                    margin-left: 8px;
                }

                .link {
                    font-size: 0.875rem;
                    color: map-get($map: $theme-colors, $key: "TEXT_LIGHT_GREY");

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }

            .MuiCheckbox-colorSecondary {
                color: map-get($map: $theme-colors, $key: "WHITE");
            }

            .MuiCheckbox-colorSecondary.Mui-checked {
                color: map-get($map: $theme-colors, $key: "ORANGE");
            }

            .MuiCheckbox-root {
                color: map-get($map: $theme-colors, $key: "WHITE");
            }

            .PrivateSwitchBase-root-7 {
                padding: 0px;
            }

            .login-button-container {
                padding: 12px 0px;
            }

            .forgotten-password {
                padding: 12px 0px;
                display: flex;
                flex: 1;

                span {
                    font-size: 0.875rem;
                    color: map-get($map: $theme-colors, $key: "ORANGE");
                    text-align: center;
                    width: 100%;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .bottom-box {
            height: 44px;
            margin: 0px 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .text {
                font-size: 0.875rem;

                &:hover {
                    cursor: pointer;
                }
            }

            .text.white {
                color: map-get($map: $theme-colors, $key: "WHITE");
            }

            .text.orange {
                color: map-get($map: $theme-colors, $key: "ORANGE");
            }

            .text.grey {
                color: map-get($map: $theme-colors, $key: "GREY");

            }
        }
    }
}

@media only screen and (min-width: $media-break-6) {
    .login {
        .login-container {
            .logo {
                width: 300px;
            }

            .login-box {
                width: 360px;
            }
        }
    }
}

@media only screen and (min-width: $media-break-5) {
    .login {
        .login-container {
            padding: 32px;

            .login-box {
                width: 420px;
                padding: 36px;
            }
        }
    }
}