.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    .header-logo {
        width: 200px;
        object-fit: contain;

        &:hover {
            cursor: pointer;
        }
    }

    .header-profile {
        img:first-child() {
            width: 38px;
            height: 38px;
        }
    }

    .header-searchbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 32px;
        border-radius: 24px;
        background-color: map-get($map: $theme-colors, $key: 'DARK_GREY');
        padding: 0px 16px;
        width: 135px;
        .day-mode & {
            background-color: map-get($map: $theme-colors, $key: 'DAY_MODE_GREY');
        }

        img {
            width: 16px;
            height: 16px;
            object-fit: contain;

            &:hover {
                cursor: pointer;
            }
        }

        .search-input {
            margin-left: 12px;
            width: 120px;
            font-size: 0.875rem;
            font-weight: 700;
            background-color: transparent;
            color: map-get($map: $theme-colors, $key: 'TEXT_WHITE');
            .day-mode & {
                color: map-get($map: $theme-colors, $key: 'GREY');
            }
            &::placeholder {
                .day-mode & {
                    color: map-get($map: $theme-colors, $key: 'GREY');
                }
            }
        }
    }

    .right-content {
        display: flex;
        align-items: center;
        justify-content: center;
        & * {
            cursor: pointer;
        }
    }

    .switch-container {
        display: flex;
        align-items: center;
        margin-top: -2px;
        position: relative;
        &:before {
            content: 'NIGHT MODE';
            position: absolute;
            z-index: 99;
            width: 32px;
            font-size: 9px;
            position: absolute;
            top: 6px;
            right: 32px;
            visibility: visible;
            opacity: 1;
            pointer-events: none;
            transition: opacity 1.2s ease-in;

            .day-mode & {
                visibility: invisible;
                opacity: 0;
                transition: opacity 0.2s ease-out;
            }
        }
        &:after {
            content: 'DAY MODE';
            color: #707070;
            font-family: 'Montserrat-SemiBold';
            line-height: 10px;
            position: absolute;
            z-index: 99;
            width: 32px;
            font-size: 9px;
            top: 9px;
            right: 6px;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            .day-mode & {
                visibility: visible;
                opacity: 1;
                transition: opacity 1.2s ease-in;
            }
        }
    }

    .switch {
        position: relative;
        width: 75px;
        height: 35px;
        -webkit-appearance: none;
        background-color: map-get($map: $theme-colors, $key: 'DARK_GREY');
        outline: none;
        border-radius: 20px;
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.65);
        margin-left: 13.6px;
        cursor: pointer;

        .day-mode & {
            background-color: map-get($map: $theme-colors, $key: 'DAY_MODE_GREY');
            box-shadow: inset 0 2px 5px rgb(215, 213, 213);
        }
        &::before {
            content: '';
            width: 30px;
            height: 30px;
            border-radius: 20px;
            background: map-get($map: $theme-colors, $key: 'ORANGE');
            position: absolute;
            top: 4.5px;
            right: 2.5px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
            transition: 0.5s;
            transform: scale(0.7);
            .day-mode & {
                background: map-get($map: $theme-colors, $key: 'ORANGE');
            }
        }
        &:checked[type='checkbox']:before {
            right: 40px;
        }
    }
}

.header.logo-header {
    background-color: map-get($map: $theme-colors, $key: 'BLACK');
    height: 76px;
    .day-mode & {
        background-color: map-get($map: $theme-colors, $key: 'DAY_MODE_GREY');
    }
}

.header.searchbar-header {
    background-color: map-get($map: $theme-colors, $key: 'LIGHT_BLACK');
    height: 76px;

    .day-mode & {
        background-color: map-get($map: $theme-colors, $key: 'TEXT_WHITE');
    }
}

.profile-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    z-index: 1;
    height: 166px;
    width: 114px;
    right: 16px;
    top: 69px;
    .day-mode & {
        background: map-get($map: $theme-colors, $key: 'DAY_MODE_GREY') !important;
    }

    span {
        font-size: 0.875rem;
        color: map-get($map: $theme-colors, $key: 'GREY');
        margin-left: 32px;

        &:hover {
            cursor: pointer;
            color: map-get($map: $theme-colors, $key: 'TEXT_WHITE');
            .day-mode & {
                color: map-get($map: $theme-colors, $key: 'GREY');
                font-family: 'Montserrat-SemiBold';
            }
        }
    }
}

@media only screen and (min-width: $media-break-6) {
    .header {
        .header-searchbar {
            padding: 0px 24px;

            .search-input {
                width: 160px;
            }
        }

        .header-logo {
            height: 44px;
            width: auto;
        }

        .header-profile {
            .profile-image {
                width: 38px;
                height: 38px;
                margin-right: 12px;
                margin-left: 26px;
                object-fit: cover;
                &.square {
                    object-fit: contain;
                }
                &.circular {
                    border-radius: 19px;
                }
            }
            .dropdown-arrow {
                cursor: pointer;
                padding: 15px;
                margin: -15px;
            }
        }
    }

    .profile-dropdown {
        top: 73px;
    }
}

@media only screen and (min-width: $media-break-4) {
    .header {
        .header-searchbar {
            height: 50px;
            width: 235px;
        }
    }

    .header.searchbar-header {
        height: 144px;
    }

    .profile-dropdown.top-lg {
        top: 107px;
    }
}

@media only screen and (min-width: $media-break-3) {
    .header {
        .header-logo {
            width: 268px;
            height: 44px;
            object-fit: contain;
        }

        .header-searchbar {
            input:focus {
                width: 240px;
            }
        }
    }
}

@media only screen and (min-width: $media-break-2) {
    .header {
        padding: 0px 24px;
        .header-searchbar {
            input:focus {
                width: 360px;
            }
        }
    }
}

@media only screen and (min-width: $media-break-1) {
    .header {
        padding: 0px 56px;
    }

    .profile-dropdown {
        right: 39px;
    }
}
