$theme-colors: (
    "LIGHT_BLACK": #151515,
    "PLAYER_BLACK": #1C1C1C,
    "BLACK": #0A0A0A,
    "ICON_ORANGE": #F77629,
    "ORANGE": #FF7000,
    "WHITE": #FFFBEB,
    "TEXT_WHITE": #FFF,
    "TEXT_LIGHT_GREY": #AEADA5,
    "GREY": #5C5B55,
    "TEXT_GREY": #75746B,
    "LIGHT_GREY": #605F5A,
    "DARK_GREY": #222222,
    "DARK_GREY_2": #282828,
    "TIMELINE_DARK": #2D2D2D,
    "TIMELINE_LIGHT": #909090,

    "DAY_MODE_GREY": #F3F3F3,
);

//Vars
$media-break-1: 1280px;
$media-break-2: 1024px;
$media-break-3: 800px;
$media-break-4: 650px;
$media-break-5: 500px;
$media-break-6: 400px;

@font-face {
    font-family: 'Montserrat';
	src: local('Montserrat-Regular'),
		 url('fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
	src: local('Montserrat-SemiBold'),
		 url('fonts/Montserrat-SemiBold.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat-Bold'),
		 url('fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
    font-family: 'MonumentExtended-Regular';
    src: local('MonumentExtended-Regular'),
        url('fonts/MonumentExtended-Regular.otf') format('opentype');
    font-style: normal;
}

@import "~bootstrap/scss/bootstrap";