.settings-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: map-get($map: $theme-colors, $key: "LIGHT_BLACK");
    overflow: scroll;
    .day-mode & {
        background-color: map-get($map: $theme-colors, $key: "TEXT_WHITE");
    }
    .settings-header {
        height: 76px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid map-get($map: $theme-colors, $key: "DARK_GREY_2");
        margin: 0px 3px;

        .headline {
            font-size: 1.6rem;
            font-weight: 700;
            color: map-get($map: $theme-colors, $key: "WHITE");
            margin: 0px 16px;
            .day-mode & {
                color: map-get($map: $theme-colors, $key: "BLACK");
            }
        }

        .nav-item {
            //height: 100%; 
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: map-get($map: $theme-colors, $key: "GREY");
            padding: 4px;
            margin: 5px 8px 0px 8px;
            border-bottom: 5px solid transparent;

            &:hover {
                cursor: pointer;
            }
            &.active {
                color: map-get($map: $theme-colors, $key: "TEXT_WHITE");
                border-bottom: 5px solid map-get($map: $theme-colors, $key: "ICON_ORANGE");
                .day-mode & {
                    color: map-get($map: $theme-colors, $key: "DARK_GREY_2");
                }
            }
        }
    }

    .content-container {
        display: flex;
        flex: 1;
        justify-content: center;
        margin: 36px 0px;

        .content {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0px 16px;
            .day-mode & {
                color: map-get($map: $theme-colors, $key: "BLACK");
            }
            .headline {
                font-size: 0.875rem;
                color: map-get($map: $theme-colors, $key: "ORANGE");
                padding: 28px 0px;
                letter-spacing: 0.1rem;
            }

            .billing-mg {
                margin-left: 8px;
            }

            .account-section {
                display: flex;
                flex-direction: row;
                border-top: 1px solid map-get($map: $theme-colors, $key: "DARK_GREY_2");
                height: 92px;
                justify-content: space-between;
                align-items: center;

                .title {
                    width: 160px;
                }

                .profile-image {
                    width: 46px;
                    height: 46px;
                    border-radius: 23px;
                    object-fit: cover;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .input {
                    height: 47px;
                    border-radius: 6px;
                    background-color: map-get($map: $theme-colors, $key: "TEXT_WHITE");
                    .day-mode & {
                        background-color: map-get($map: $theme-colors, $key: "DAY_MODE_GREY");
                    }
                    font-size: 0.875rem;
                    color: map-get($map: $theme-colors, $key: "LIGHT_BLACK");
                    border: none;
                    padding: 0px 16px;
                    max-width: 140px;
                    display: flex;
                    flex: 1;
                }

                .text {
                    font-size: 0.75rem;
                    letter-spacing: 0.1rem;
                    color: map-get($map: $theme-colors, $key: "ORANGE");
                    text-align: right;
                    display: flex;
                    flex: 1;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .account-section.bottom-border {
                border-bottom: 1px solid map-get($map: $theme-colors, $key: "DARK_GREY_2");
            }

            .headline.bottom-border {
                border-bottom: 1px solid map-get($map: $theme-colors, $key: "DARK_GREY_2");
                margin-bottom: 28px;
                margin-top: 40px;
            }

            .update {
                margin-top: 40px;
                margin-bottom: 20px;
            }
            .categories {
                list-style: none;
                padding: unset;
                li {
                    &:first-child() {
                        padding-top: 0;
                    }
                    display: flex;
                    justify-content: space-between;
                    padding-top: 35px;
                    font-size: 16px;
                }
            }
            .switch-container {
                display: flex;
                align-items: center;
                margin-top: -2px;
                position: relative;
                .switch {
                    position: relative;
                    width: 75px;
                    height: 35px;
                    -webkit-appearance: none;
                    background-color: map-get($map: $theme-colors, $key: "DARK_GREY");
                    outline: none;
                    border-radius: 20px;
                    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.65);
                    margin-left: 13.6px;
                    cursor: pointer;
                    
                    .day-mode & {
                        background-color: map-get($map: $theme-colors, $key: "DAY_MODE_GREY");
                        box-shadow: inset 0 2px 5px #d7d5d561;
 
                    }
                    &::before {
                        content: "";
                        width: 30px;
                        height: 30px;
                        border-radius: 20px;
                        background: map-get($map: $theme-colors, $key: "ORANGE");
                        position: absolute;
                        top: 4.5px;
                        right: 2.5px;
                        box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
                        transition: 0.5s;
                        transform: scale(0.7);
                        .day-mode & {
                            background: map-get($map: $theme-colors, $key: "ORANGE");
                        } 
                    }
                    &:checked[type="checkbox"]:before {
                        right: 40px;
                    }
                }
                .switch-text {
                    position: absolute;
                    z-index: 99;
                    width: 32px;
                    pointer-events: none;
                    word-wrap: none;
                    white-space: nowrap;
                    font-size: 16px;
                    top: 6px;
                    right: 160px;
                }
            }


            .themes-container {
                margin-top: 28px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .theme-container {
                    margin-bottom: 28px;
                    img {
                        margin-bottom: 27.5px;
                        cursor: pointer;
                    }
                    input {
                        margin-right: 11.5px;
                    }
                }
            }

            .chord-type-container {
                display: flex;
                justify-content: space-between;
            }

            .save-settings {
                display: flex;
                justify-content: center;
                margin-top: 77.6px;
            }

            .billing-section {
                display: flex;
                flex-direction: column;

                .column {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    margin: 12px 0px;

                    .head {
                        margin: 4px 0px 8px;

                        span {
                            color: map-get($map: $theme-colors, $key: "GREY");
                        }
                    }
                }

                .column.column-1 {
                    width: 180px;
                }

                .column.column-2 {
                    flex: 1;
                }

                .column.column-3 {
                    width: 160px;
                }
            }

            .receipts-container {
                display: flex;
                flex-direction: column;

                .receipts-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 48px;
                    padding: 0px 16px;

                    .column {
                        display: flex;
                        flex: 1
                    }

                    .column.column-2 {
                        display: none;
                    }

                    .column-4 {
                        color: map-get($map: $theme-colors, $key: "ORANGE");
                        width: 40px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .receipts-row.black {
                    background-color: #000;
                    .day-mode & {
                        background-color: map-get($map: $theme-colors, $key: "TEXT_LIGHT_GREY");
                    }
                }

                .receipts-row.receipts-header {
                    color: map-get($map: $theme-colors, $key: "GREY");
                    font-size: 0.875rem;
                }
            }

            .billing-button {
                padding: 36px 0px 44px;
                margin-bottom: 20px;
            }

            .billing-button.bottom-border {
                border-bottom: 1px solid map-get($map: $theme-colors, $key: "DARK_GREY_2");
            }

        }
    }
}

@media only screen and (min-width: $media-break-6) {
    .settings-container {
        .settings-header {
            .headline {
                font-size: 1.8rem;
            }

            .nav-item {
                margin: 5px 20px 0px 20px;
            }
        }

        .content-container {
            .content {
                .account-section {
                    .input {
                        max-width: 408px;
                    }
                }

                .receipts-container {
                    .receipts-row {
                        .column.column-1 {
                            width: 80px;
                            flex: inherit;
                        }

                        .column.column-2 {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $media-break-6) and (max-width: $media-break-5) {
    .settings-container {
        .content-container {
            .content {
                .receipts-container {
                    .receipts-row {

                        .column.column-3 {
                            width: 60px;
                            flex: inherit;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $media-break-5) {
    .settings-container {
        .settings-header {
            .headline {
                margin: 0px 44px 0px 52px;
            }

            .nav-item {
                margin: 5px 20px 0px 20px;
            }
        }
        
        .content-container {
            .content {
                padding: 0px 24px;
            }
        }
    }
}

@media only screen and (min-width: $media-break-4) {
    .settings-container {
        .content-container {
            .content {
                .themes-container {
                    flex-direction: row;
                    justify-content: flex-start;
                    .theme-container {
                        &:first-child() {
                            margin-right: 40px;
                        }
                    }
                }
                .save-settings {
                    justify-content: flex-start;
                }
                .billing-section {
                    flex-direction: row;

                    .column {
                        flex: inherit;
                        margin: 0px;

                        .head {
                            margin: 4px 0px 16px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $media-break-3) {

    .settings-container {

        .content-container {

            .content {
                width: 800px;

                .billing-section {
                    .column.column-1 {
                        width: 270px;
                    }
                }

                .receipts-container {
                    .receipts-row {
                        .column.column-1 {
                            flex: inherit;
                            width: 190px;
                        }
    
                        .column.column-3 {
                            flex: inherit;
                            width: 200px;
                        }
                    }
                }
            }
        }
    }

}