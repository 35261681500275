.player-container {
    background-color: map-get($map: $theme-colors, $key: "PLAYER_BLACK");
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;

    &.hidden {
        height: 0;
        overflow: hidden;
    }

    .title-artist-column {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
        padding-right: 12px;

        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .title {
            color: map-get($map: $theme-colors, $key: "WHITE");
            font-size: 0.875rem;
        }

        .artist {
            color: map-get($map: $theme-colors, $key: "GREY");
            font-size: 0.875rem;
            margin-top: 8px;
        }
    }

    .play-column {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;

        .button-row {
            display: flex;
            flex-direction: row;

            .icon-container {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 12px 0px;

                &:hover {
                    cursor: pointer;
                }

                .spool-icon {
                    width: 17px;
                    object-fit: contain;
                }

                .like-icon {
                    width: 19px;
                    object-fit: contain;
                }

                .play-icon {
                    width: 40px;
                    object-fit: contain;
                }

            }

            .icon-container.like {
                display: none;
            }

            .icon-container.rewind{
                margin-left: 0px;
            }

            .icon-container.ff {
                margin-right: 0px;
            }
        }

        .timeline-row {
            display: flex;
            width: 100%;
            max-width: 740px;
            flex-direction: row;
            align-items: center;
            padding: 0px 12px;

            .time {
                width: 48px;
                font-size: 0.875rem;
                color: map-get($map: $theme-colors, $key: "GREY")
            }

            .time.right {
                text-align: right;
            }
        }
    }

    .volume-column {
        display: none;
    }

    .percent-line-container {
        position: relative;
        width: 100%;
        margin: 0px 8px;

        .percent-line-drag {
            cursor: pointer !important;
            height: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
        }

        .percent-line {
            height: 4px;
            border-radius: 2px 2px 1px 1px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            &.back {
                background-color: map-get($map: $theme-colors, $key: "TIMELINE_DARK");
                width: 100%;
            }
            &.front {
                background-color: map-get($map: $theme-colors, $key: "TIMELINE_LIGHT");
            }
        }

        .indeterminate, .indeterminate:before {
            height: 2px;
            width: 100%;
            margin: 0;
        }
        .indeterminate {
            display: -webkit-flex;
            display: flex;
        }
        .indeterminate::before {
            background-color: map-get($map: $theme-colors, $key: "ORANGE");;
            content: '';
            -webkit-animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
            animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
        }
        @-webkit-keyframes running-progress {
            0% { margin-left: 0px; margin-right: 100%; }
            50% { margin-left: 25%; margin-right: 0%; }
            100% { margin-left: 100%; margin-right: 0; }
        }
        @keyframes running-progress {
            0% { margin-left: 0px; margin-right: 100%; }
            50% { margin-left: 25%; margin-right: 0%; }
            100% { margin-left: 100%; margin-right: 0; }
        }
    }
}

@media only screen and (min-width: $media-break-5) {
    .player-container {
        padding: 0px 24px;
        .title-artist-column {
            flex: 0 0 220px;
        }
        .play-column {
            .button-row {
                .icon-container.like {
                    display: flex;
                }

                .icon-container.rewind{
                    margin-left: 8px;
                }
    
                .icon-container.ff {
                    margin-right: 8px;
                }
            }
        }
    }
}

@media only screen and (min-width: $media-break-4) {
    .player-container {
        .title-artist-column {
            flex: 0 0 440px;
        }
        .play-column {
            .button-row {
                .icon-container {
                    margin: 12px;
                }
            }
        }
    }
}

@media only screen and (min-width: $media-break-3) {
    .player-container {

        .volume-column {
            display: flex;
            justify-content: flex-end;
            flex: 0 0 180px;
            padding-left: 24px;

            .volume-container {
                display: flex;
                width: 100%;
                align-items: center;
                flex-direction: row;

                .speaker {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 16px;

                    &:hover {
                        cursor: pointer;
                    }

                    .speaker-icon {
                        width: 25px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $media-break-2) {
    .player-container {
        padding: 0px 52px;

        .volume-column {
            flex: 0 0 440px;

            .volume-container {
                width: 46%;
            }
        }
    }
}