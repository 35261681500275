.PrivacyPolicy {
    display: flex;
    justify-content: center;
    padding: 10% 5%;
    background: white;
    color: black;
    font-size: 20px;

    img {
        max-height: 64px;
        @media all and (max-width: 550px) {
            width: 100%;
        }
    }
    * {
        margin-bottom: 30px;
    }
    .content-container {
        max-width: 1200px;
        h5 {
            font-size: 20px;
            font-weight: 600;
        }
        ul {
            list-style: none;
            li {
                margin-bottom: 5px;
            }
        }
    }
}

.space-top {
    margin-top: 30px;
}
