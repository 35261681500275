.dashboard-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    overflow: scroll;
    height: 100%;
    
    .top-container {
        display: flex;
        flex-direction: row;
        background-color: map-get($map: $theme-colors, $key: "LIGHT_BLACK");
        .day-mode & {
            background-color: map-get($map: $theme-colors, $key: "TEXT_WHITE");
        }

        .left-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .day-mode & {
                background-color: map-get($map: $theme-colors, $key: "TEXT_WHITE");
            }

            .mark-container {
                height: 76px;
                display: flex;
                width: 46px;
                justify-content: center;
                align-items: center;

                .clubsets-mark {
                    width: 30px;
                    height: 30px;
                    object-fit: contain;
                }
            }

            .burger-menu {
                display: flex;
                flex-direction: column;
                margin: 4px 16px;
                width: 30px;

                &:hover {
                    cursor: pointer;
                }

                .menu-line {
                    width: 100%;
                    height: 3px;
                    margin: 3px 0px;
                    background-color: map-get($map: $theme-colors, $key: "ORANGE");
                }
            }
        }


    }

    .bottom-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        position: relative;

        .side-menu-container {
            display: none;
            position: absolute;
            top: 0px;
            left: 0;
        }

        .side-menu-container.show {
            display: flex;
            width: 100%;
        }

        .content-container {
            padding: 8px 16px 0px;
            padding-right: 1rem !important;
            background-color: map-get($map: $theme-colors, $key: "LIGHT_BLACK");
            min-width: 0;
            display: flex;
            flex-direction: column;
            flex: 1;
            .day-mode & {
                background-color: map-get($map: $theme-colors, $key: "TEXT_WHITE");
            }

            .content-headline {
                margin-bottom: 24px;
                .day-mode & {
                    color: map-get($map: $theme-colors, $key: "BLACK");
                }

                span {
                    font-size: 2rem;
                    letter-spacing: -0.05rem;
                    font-weight: 700;
                }
            }
        }
    }
}

.dashboard-container.player-pd {
    padding-bottom: 142px;
}

@media only screen and (min-width: $media-break-6) {
    .dashboard-container {
        .top-container {
            .left-container {
                .mark-container {
                    width: 60px;

                    .clubsets-mark {
                        width: 44px;
                        height: 44px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $media-break-3) {
    .dashboard-container {
        .top-container {
            .left-container {
                .mark-container {
                    height: 144px;
                    width: 200px;
                    background-color: map-get($map: $theme-colors, $key: "BLACK");
                    .day-mode & {
                        background-color: map-get($map: $theme-colors, $key: "DAY_MODE_GREY");
                    }
                    
                    .clubsets-mark {
                        width: 64px;
                        height: 64px;
                    }
                }

                .burger-menu {
                    display: none;
                }
            }
        }

        .bottom-container {
            .side-menu-container {
                display: flex;
                position: static;
            }
        }
    }
}

@media only screen and (min-width: $media-break-3) {
    .dashboard-container {
        .bottom-container {
            .content-container {
                .content-headline {
                    margin-bottom: 48px;
    
                    span {
                        font-size: 2.5rem;
                    }
                }
            }
        }
    }

}

@media only screen and (min-width: $media-break-2) {
    .dashboard-container {
        .top-container {
            .left-container {
                .mark-container {
                    width: 220px;
                }
            }
        }

        .bottom-container {
            .content-container {
                padding: 8px 24px 0px;
            }
        }
    }
}

@media only screen and (min-width: $media-break-1) {
    .dashboard-container {
        .top-container {
            .left-container {
                .mark-container {
                    width: 240px;
                }
            }
        }
        .bottom-container {
            .content-container {
                padding: 8px 56px 0px;
            }
        }
    }
}