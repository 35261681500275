.button {
    height: 53px;
    padding: 0px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: map-get($map: $theme-colors, $key: "ORANGE");
    border-radius: 26px;
    border-color: transparent !important;
    color: map-get($map: $theme-colors, $key: "TEXT_WHITE");
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    &:hover {
        cursor: pointer;
    }
}

//Layout for Smartphone


//Layout for Tablet
@media only screen and (min-width: $media-break-3) {}

//Layout for Desktop
@media only screen and (min-width: $media-break-2) {

}