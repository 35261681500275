.premium-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    .day-mode & {
        background-color: map-get($map: $theme-colors, $key: "DAY_MODE_GREY");
    }
    .membership-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: map-get($map: $theme-colors, $key: "LIGHT_BLACK");
        padding: 0px 24px;
        .day-mode & {
            background-color: map-get($map: $theme-colors, $key: "TEXT_WHITE");
        }
        .select-container {
            display: flex;
            flex-direction: column;
            margin: 16px 0px;

            .select-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 8px 0px;
                .day-mode & {
                    color: map-get($map: $theme-colors, $key: "BLACK");
                }
                &:hover {
                    cursor: pointer;
                }
                .selected {
                    color: white;
                    .day-mode & {
                        color: map-get($map: $theme-colors, $key: "ORANGE");
                    }
                }
                .not-selected {
                    color: map-get($map: $theme-colors, $key: "GREY");
                }
                .step {
                    width: 26px;
                    height: 26px;
                    border-radius: 13px;
                    border: 1px solid map-get($map: $theme-colors, $key: "ORANGE");
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 12px;

                    span {
                        font-size: 0.875rem;
                    }
                }
            }
        }

        .headline {
            margin: 16px 0px;

            span {
                font-size: 2.25rem;
                font-weight: 700;
            }
        }

        .subhead {
            margin: 12px 0px;

            span {
                display: flex;
                color: map-get($map: $theme-colors, $key: "LIGHT_GREY");
                text-align: center;
                line-height: 1.5rem;
            }
        }

        .premium-box {
            margin: 40px 0px 90px;

            .info {
                line-height: 1.5rem;
            }

            .button {
                width: 220px;
            }

            .checkout {
                display: flex;
                flex-direction: column;
                .day-mode & {
                    background-color: map-get($map: $theme-colors, $key: "DAY_MODE_GREY");
                    color: map-get($map: $theme-colors, $key: "GREY");
                }
                >:not(.StripeElement) {
                    align-self: center;
                }
                .input-container {
                    align-self: stretch;
                    margin: 0px 20px;
                    .input {
                        background-color: transparent;
                        border-color: #fff !important;
                        border-width: 1px !important;
                        border-style: solid !important;
                        color: white;
                        .day-mode & {
                            color: map-get($map: $theme-colors, $key: "GREY");
                            border-color: map-get($map: $theme-colors, $key: "GREY") !important;
                        }
                    }
                    span {
                        .day-mode & {
                            color: map-get($map: $theme-colors, $key: "TEXT_GREY");
                        }
                    }
                }
                .info-container {
                    padding: 32px 24px 60px;
                }
                .StripeElement {
                    height: 100%;
                    >div {
                        height: 100%;
                    }
                }
                .button-container {
                    margin-top: 40px;
                    margin-bottom: 40px;
                    text-align: center;
                    .button {
                        border-radius: 6px;
                    }

                }
                .promo-code {
                    font-size: .8rem;
                    color: green;
                    margin-bottom: 8px;
                    padding: 0 24px;
                }
                .or-payment {
                    font-size: .8rem;
                    margin-bottom: 14px;
                    margin-top: 14px;
                    display: block;
                }
            }

            .premium-inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                padding: 32px 24px 60px;

                .day-mode & {
                    background-color: map-get($map: $theme-colors, $key: "DAY_MODE_GREY");
                    color: map-get($map: $theme-colors, $key: "GREY");
                }

                .price-container {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;

                    .price {
                        font-size: 3.047rem;
                        font-weight: 700;
                    }
                }
                .button {
                    margin-top: 40px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media only screen and (min-width: $media-break-6) {
    .premium-container {
        .membership-container {
            .premium-box {
                .premium-inner {
                    .button {
                        width: 250px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $media-break-5) {
    .premium-container {

        .membership-container {

            .select-container {
                flex-direction: row;
                width: 385px;
                justify-content: space-between;
                margin: 42px 0px;
            }

            .premium-box {
                width: 450px;

                .premium-inner {
                    padding: 32px 56px 60px;
                }
            }
        }
    }
}


@media only screen and (min-width: $media-break-4) {
    .premium-container {
        .membership-container {
            .subhead {
                width: 560px;
            }
        }
    }
}

@media only screen and (min-width: $media-break-2) {
    .premium-container {
        .faq-container {
            .faq-inner {
                width: 917px;
            }
        }
    }
}