.box {
    border-radius: 10px;

    .header {
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: map-get($map: $theme-colors, $key: "ORANGE");
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}

//Layout for Smartphone


//Layout for Tablet
@media only screen and (min-width: $media-break-3) {}

//Layout for Desktop
@media only screen and (min-width: $media-break-2) {

}