.faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .faq-inner {
        margin: 78px 16px 114px;
        width: 80%;
        max-width: 800px;
        .faq-headline {
            margin: 16px;
            display: flex;
            justify-content: center;
            .day-mode & {
                color: map-get($map: $theme-colors, $key: 'BLACK');
            }
            span {
                font-size: 2.25rem;
                font-weight: 700;
            }
        }

        .faq-item {
            border-bottom: 1px solid map-get($map: $theme-colors, $key: 'DARK_GREY');
            padding: 0px 8px;

            .question {
                display: flex;
                height: 72px;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .day-mode & {
                    color: map-get($map: $theme-colors, $key: 'BLACK');
                }
                &:hover {
                    cursor: pointer;
                }

                span {
                    display: flex;
                    flex: 1;
                    font-size: 1.125rem;
                }

                .icon {
                    width: 18px;
                    height: 11px;
                    object-fit: contain;
                    margin-left: 8px;
                }
            }

            .answer {
                margin-bottom: 32px;

                span {
                    color: map-get($map: $theme-colors, $key: 'LIGHT_GREY');
                    line-height: 1.5rem;
                }
            }
        }
    }
}
